	.heading{
	  text-align: center;
	  line-height: 30px;
	  font-size: 15px;
	  color: #969ba0;
	  font-weight: 700;
	}
	.major{
	  display: flex;
	  justify-content: space-around;
	  padding:5px;
	}
	.holder {
	  margin: 0px;
	  text-align: center;
	}
	.larger{
		line-height: 22px;
	    font-size: 18px;
	    font-weight: 700;
	}
	.smaller{
		line-height: 22px;
	    font-size: 11px;
	    font-weight: 400;
	}
	
	.num-plus {
		color: #0cabc2;
	}
	
	.num-minus {
		color: #e14d43;
	}
	
	.num-grey {
		color: #969ba0;
	}
	
	.date-separator {
		width: 28px;
		font-size: 24px;
		line-height: 32px;
		text-align: center;
		color:green;
	}